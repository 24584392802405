import Swal from 'sweetalert2'

export function errorsAlertApi(error) {
    const errorType = error.response.data.error;
    let textError = '';

    if(errorType.hasOwnProperty('details') ){
        let messages = [];
        const errors = errorType.details;

        if(Array.isArray(errorType.details)){
            errors.map(item => {
                messages.push(item.message)
            });
            textError = messages.join(', ');
        } else {
            textError = errors;
        }

    } else {
        textError = errorType.message;
    }

    Swal.fire({
        title: 'Ошибка!',
        icon: 'error',
        confirmButtonText: 'Ok',
        text: textError
    });

    return error;
}